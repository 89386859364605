import styled from '@emotion/styled';
import ArtistHubIcon from './artist-hub.svg';
import InstantMasterinProductIcon from './mastring.svg';
import MusicDistributionProductIcon from './dmd.svg';
import { BodySmall, Card, LinkButton } from 'imdui';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const IconHeader = styled.div`
  height: 48px;
  position: relative;
  svg {
    position: absolute;
  }
`;
const ProductButton = styled(LinkButton)`
  margin-top: 32px;
`;

const ProductCardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  transition: all 0.2s ease-in-out;

  ${BodySmall} {
    color: var(--on-surface-var, #353534);
    font-feature-settings: 'ss01' on;
  }
  div {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    gap: 12px;
  }
`;
const ProductCard = styled(Card)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 32px;

  border-radius: 40px;
  border: 1px solid var(--outline-var, #c9c6c4);
  transition: all 0.2s ease-in-out;
  background: var(--surface-container-lowest, #edeae8);

  /* Shadow/1 */
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.05),
    0px 0px 4px 0px rgba(0, 0, 0, 0.05);

  svg {
    transition: all 0.2s ease-in-out;
    width: 32px;
    height: 32px;
  }
  &:hover {
    border: 1px solid var(--accent, #00a743);
    background: var(--accent-surface-container, #fefffe);
    ${ProductButton} {
      --fg-1: var(--on-accent);
      --surface-container-high: var(--accent);
    }
  }
`;

const ProductTitle = styled.div`
  color: var(--on-surface, #1c1c1b);
  font-feature-settings:
    'ss01' on,
    'cv11' on;
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 24px */
  letter-spacing: 0.48px;
  text-transform: uppercase;
`;

export const DashboardProductRow = () => {
  const histpry = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <ProductCard
        onClick={() => {
          histpry.push('/order/md/new');
          if (window?.analytics) {
            window.analytics.track('FT Product Dashboard Click', {
              product: 'music-distribution',
            });
          }
        }}
      >
        <IconHeader>
          <MusicDistributionProductIcon />
        </IconHeader>

        <ProductCardContent>
          <div>
            <ProductTitle>{t('distribute-music')}</ProductTitle>
            <BodySmall>{t('music-distribution-onboarding-text')}</BodySmall>
          </div>
          <ProductButton
            testId="start-distributing"
            to="/order/md/new"
            primary={true}
            text={t('explore')}
          />
        </ProductCardContent>
      </ProductCard>
      <ProductCard
        onClick={() => {
          histpry.push('/order/mastering/new');
          if (window?.analytics) {
            window.analytics.track('FT Product Dashboard Click', {
              product: 'mastering',
            });
          }
        }}
      >
        <InstantMasterinProductIcon />
        <ProductCardContent>
          <div>
            <ProductTitle>{t('instant-mastering')}</ProductTitle>
            <BodySmall>{t('instant-mastering-dashboard-text')}</BodySmall>
          </div>
          <ProductButton
            to="/order/mastering/new"
            primary={true}
            text={t('master-my-track')}
          />
        </ProductCardContent>
      </ProductCard>
      <ProductCard
        onClick={() => {
          histpry.push('/products/artist-hub');
          if (window?.analytics) {
            window.analytics.track('FT Product Dashboard Click', {
              product: 'release-page',
            });
          }
        }}
      >
        <ArtistHubIcon />
        <ProductCardContent>
          <div>
            <ProductTitle>{t('brand-and-release-promo')}</ProductTitle>
            <BodySmall>{t('artist-hub-dashboard-text')}</BodySmall>
          </div>
          <ProductButton
            size="medium"
            to="/products/artist-hub"
            primary={true}
            text={t('explore-artist-hub')}
          />
        </ProductCardContent>
      </ProductCard>
    </>
  );
};
