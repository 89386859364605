import styled from '@emotion/styled';
import React from 'react';
import { Body, Content } from '../../../text';
import Clickable from '../../button/Clickable';

const ListItemClickable = styled(Clickable)`
  display: flex;
  align-items: center;
  background: var(--surface-container-high, #fff);
  width: 100%;
  min-height: 40px;
  border-radius: 0;
  padding: 0 16px;
  word-break: break;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: 16px;
  width: 24px;
`;

const TextContainer = styled.div`
  flex: 1;
  vertical-align: baseline;
  text-align: left;
  display: inline-flex;
  align-items: center;

  > ${Content} {
    font-size: 14px;
  }
`;

const RightElementsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 20px;
`;

const Prefix = styled(Body)`
  display: block;
  width: 128px;
  margin-right: 8px;
  color: ${({ theme }): string => theme.foreground.secondary};
`;

interface Props {
  testId?: string;
  label?: string | React.ReactNode;
  prefix?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selected?: boolean;
  focused?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  renderRightChildren?: (props?: {
    focused?: boolean;
    selected?: boolean;
  }) => any;
  children?: React.ReactNode;
  className?: string;
  style?: any;
}

export default function ListItem({
  label,
  prefix,
  icon = null,
  focused,
  selected,
  disabled,
  onClick,
  renderRightChildren = (): null => null,
  className,
  style,
  testId,
  children,
}: Props): JSX.Element {
  return (
    <ListItemClickable
      testId={testId}
      disabled={disabled}
      className={className}
      style={style}
      onClick={onClick}
    >
      {children ? (
        <Row>{children}</Row>
      ) : (
        <Row>
          <Row>
            {icon !== null && <IconContainer>{icon}</IconContainer>}

            <TextContainer>
              {prefix && <Prefix>{prefix}</Prefix>}
              {typeof label === 'string' ? <Content>{label}</Content> : label}
            </TextContainer>
          </Row>

          <RightElementsContainer>
            {renderRightChildren && renderRightChildren({ focused, selected })}
          </RightElementsContainer>
        </Row>
      )}
    </ListItemClickable>
  );
}
