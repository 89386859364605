import React, {
  memo,
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { validateBarcode } from 'imddata/actionTypes/releases';
import memoize from 'fast-memoize';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import type { ReduxState } from 'imddata';
import {
  AppContext,
  EntityFormContext,
  useUpdateEntity,
  useCustomerFeatureRollout,
} from 'imddata';
import {
  ComponentIcons,
  Body,
  Button,
  OverlineText,
  Content,
  HelpWindowContext,
  SubscriptionFeatureGate,
  createInputWithStyles,
} from 'imdui';
import { useDispatch, useSelector } from 'react-redux';
import type { CommonFieldProps } from 'redux-form';
import { formValueSelector, stopSubmit, Field, FieldArray } from 'redux-form';
import styled from '@emotion/styled';
import { useFormDisplayArtist, ArtistJoinField } from '../../ArtistJoin';
import { ReleaseFormContext } from '../ReleaseFormContext';
import LabelTextField from '../../LabelTextField';
import { createSelector } from '../../../helpers';
import {
  OriginalReleaseDateSelectorField,
  MomentDateField,
  DatePickerInputField,
  NewInputField,
  LabelSelectorField,
  GenreSelectField,
  FieldUpdatable,
  SelectField,
  RevenueSplitsField,
} from '../../../fields';

import { sectionStyle, displayArtistStyle, fieldStyle } from '../styles';
import { useSubscriptionUpsell } from '../../../logic';
import { useFeature } from 'imdfeature';
import { BuyOrSubOffer } from '../../BuyOrSubOffer';

const nameFields = ['languageId', 'version', 'promotionalText', 'title'];
const SelectIsCompilation = createSelector('hasTracksWithVariousArtists');
const SelectLanguageId = createSelector('languageId');

const LabelSection = styled.section`
  ${sectionStyle}
`;

const Subheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

const useReleaseFieldsHandlers = ({
  releaseId,
  form,
}: {
  releaseId: string | number;
  form: string;
}) => {
  const selector = useMemo(() => formValueSelector(form), [form]);
  const name = useSelector((state: ReduxState) =>
    selector(state, ...nameFields)
  );

  const { updateEntry } = useUpdateEntity({
    id: releaseId,
    entity: 'releases',
  });
  const dispatch = useDispatch();
  const handleBarcode = useMemo<CommonFieldProps['onChange']>(
    () => (_event, value) => {
      dispatch(
        validateBarcode({
          entityKey: 'releases',
          value,
          form,
          releaseId,
        })
      );
    },
    [releaseId]
  );

  const handleNameField = useCallback(
    memoize((key: string) => (event: any, value: any) => {
      updateEntry(
        {
          formId: form,
          data: {
            names: [
              {
                ...name,
                [key]: value,
              },
            ],
          },
        },
        { debounce: true }
      );
    }),
    [releaseId, name?.version, name?.title, name?.promotionalText]
  );

  const handleField = useCallback(
    memoize((key) => (event: any, value: any) => {
      updateEntry(
        {
          formId: form,
          query: key === 'artists' ? { with: 'artists' } : null,
          data: { [key]: value },
        },
        { debounce: true }
      );
    }),
    [releaseId]
  );

  return {
    handleField,
    handleNameField,
    handleBarcode,
  };
};

const StyledMaskedInput = createInputWithStyles(MaskedInput);

const CURRENT_YEAR = new Date().getFullYear();

const barcodeMask = (raw: string) =>
  raw.length <= 12 || (raw.length === 13 && raw[12] === '_')
    ? [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
    : [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,

        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,

        /\d/,
        /\d/,
        /\d/,
      ];

const renderBarcodeInput = (props: any) => (
  <StyledMaskedInput
    {...props}
    guide={true}
    placeholderChar="_"
    placeholder="0000000000000"
    mask={barcodeMask}
  />
);

export const ReleasePageFieldsColumn = ({
  displayBarcodeInput,
  releasePageId,
}: {
  displayBarcodeInput: boolean;
  releasePageId?: string;
}) => {
  const { editableFields, releaseId, form } = useContext(ReleaseFormContext);

  const displayArtist = useFormDisplayArtist(form);
  const { handleField, handleNameField } = useReleaseFieldsHandlers({
    form,
    releaseId,
  });

  const validBarcode = useSelector((state: ReduxState) => {
    if (!releaseId) return false;
    // @ts-ignore
    return state.entities.releases.entities[releaseId]?.targets.hasAllMetadata
      ?.conditions?.hasCorrectCustomBarcode?.isMet;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!validBarcode) {
      dispatch(
        stopSubmit(form, {
          customBarcode: 'barcode-not-valid',
        })
      );
    }
  }, [validBarcode]);

  const { updateEntry: updateRelease } = useUpdateEntity({
    id: releaseId,
    entity: 'releases',
  });

  const { updateEntry: updateReleasePage } = useUpdateEntity({
    id: releasePageId,
    entity: 'releasePages',
  });
  const showHelpWindow = useContext(HelpWindowContext);
  const { t } = useTranslation();

  return (
    <>
      <section css={sectionStyle}>
        {displayBarcodeInput && (
          <FieldUpdatable
            name="customBarcode"
            disabled={editableFields ? !editableFields.customBarcode : false}
            // @ts-ignore
            onChange={(_e: any, v: any) => {
              if (releasePageId) {
                updateReleasePage({ data: { gtin: v } }, { debounce: true });
              }
              updateRelease(
                { formId: form, data: { customBarcode: v } },
                { debounce: true }
              );
            }}
            label={t('barcode')}
            helperText={t('release-page-helptext-short-barcode')}
            onClickHelp={() => {
              showHelpWindow(t('barcode'), t('release-page-helptext-barcode'));
            }}
            renderInput={renderBarcodeInput}
            css={fieldStyle}
            component={NewInputField}
          />
        )}
        <Field
          name="title"
          testId="title"
          disabled={editableFields ? !editableFields.title : false}
          onChange={handleNameField('title')}
          label={t('release-name')}
          helperText={t('release-helptext-short-release-name')}
          css={fieldStyle}
          onClickHelp={() => {
            showHelpWindow(
              t('release-name'),
              t('release-helptext-release-name')
            );
          }}
          component={NewInputField}
        />
        <MomentDateField
          disabled={
            editableFields ? !editableFields.originalReleaseDate : false
          }
          name="originalReleaseDate"
          onChange={(_e: any, v: any) => {
            if (releasePageId) {
              updateReleasePage({ data: { releaseAt: v } });
            }
            updateRelease({ formId: form, data: { originalReleaseDate: v } });
          }}
          floatingLabelText={t('release-date')}
          css={fieldStyle}
          isOutsideRange={() => false}
          // @ts-ignore
          component={DatePickerInputField}
        />
      </section>

      <section css={sectionStyle}>
        <Field
          name="artists"
          size="large"
          label={t('artists-details')}
          component={LabelTextField}
          onClickHelp={() => {
            showHelpWindow(t('artists'), t('track-helptext-artists'));
          }}
        />

        <SelectIsCompilation form={form}>
          {(hasTracksWithVariousArtists: boolean) => (
            <>
              {displayArtist && (
                <Content
                  key="displayArtist"
                  css={displayArtistStyle}
                  className={hasTracksWithVariousArtists ? 'disabled' : ''}
                >
                  {displayArtist}
                </Content>
              )}

              <SelectLanguageId form={form}>
                {(languageId: string) => (
                  <Field
                    name="artists"
                    // @ts-ignore
                    testId="artists"
                    disabled={
                      hasTracksWithVariousArtists || editableFields
                        ? !editableFields?.artists
                        : false
                    }
                    onChange={handleField('artists')}
                    label={t('artists')}
                    languageId={languageId}
                    helperText={t('track-helptext-short-artists')}
                    component={ArtistJoinField}
                  />
                )}
              </SelectLanguageId>
            </>
          )}
        </SelectIsCompilation>
      </section>
    </>
  );
};

export const BaseReleaseFields = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { editableFields, releaseId, form } = useContext(ReleaseFormContext);

  const displayArtist = useFormDisplayArtist(form);
  const { handleField, handleNameField } = useReleaseFieldsHandlers({
    form,
    releaseId,
  });
  const showHelpWindow = useContext(HelpWindowContext);
  const { t } = useTranslation();

  return (
    <div>
      <section css={sectionStyle}>
        <Field
          name="title"
          testId="title"
          disabled={editableFields ? !editableFields.title : false}
          onChange={handleNameField('title')}
          label={t('release-name')}
          helperText={t('release-helptext-short-release-name')}
          css={fieldStyle}
          onClickHelp={() => {
            showHelpWindow(
              t('release-name'),
              t('release-helptext-release-name')
            );
          }}
          component={NewInputField}
        />
        {/* <FieldUpdatable */}
        {/*   name="copyrightText" */}
        {/*   // @ts-ignore */}
        {/*   onChange={handleField('copyrightText')} */}
        {/*   disabled={editableFields ? !editableFields.copyrightText : false} */}
        {/*   testId="copyrightText" */}
        {/*   label={t('copyright-recording')} */}
        {/*   onClickHelp={() => */}
        {/*     showHelpWindow( */}
        {/*       t('copyright-recording'), */}
        {/*       t('release-helptext-copyright-recording') */}
        {/*     ) */}
        {/*   } */}
        {/*   // @ts-ignore */}
        {/*   css={fieldStyle} */}
        {/*   component={NewInputField} */}
        {/* /> */}
      </section>
      <section css={sectionStyle}>
        <Field
          name="artists"
          label={t('artists-details')}
          component={LabelTextField}
          onClickHelp={() => {
            showHelpWindow(t('artists'), t('track-helptext-artists'));
          }}
        />

        {displayArtist && (
          <Content key="displayArtist" css={displayArtistStyle}>
            {displayArtist}
          </Content>
        )}

        <SelectLanguageId form={form}>
          {(languageId: string) => (
            <Field
              name="artists"
              // @ts-ignore
              testId="artists"
              disabled={editableFields ? !editableFields?.artists : false}
              onChange={handleField('artists')}
              label={t('artists')}
              languageId={languageId}
              helperText={t('track-helptext-short-artists')}
              component={ArtistJoinField}
            />
          )}
        </SelectLanguageId>
      </section>
      {children}
    </div>
  );
};

export const ExtendedFieldsColumn = memo(
  ({
    enableArtistLimit,
    displayGenre,
    displayPromotionalText,
  }: {
    enableArtistLimit?: boolean;
    displayPromotionalText?: boolean;
    displayGenre?: boolean;
  }) => {
    const app = useContext(AppContext);

    const { editableFields, releaseNameId, releaseId, form } =
      useContext(ReleaseFormContext);

    const selector = useMemo(() => formValueSelector(form), [form]);

    const isElectronic = useSelector((state: ReduxState) => {
      const genreId = state.entities.releases.entities[releaseId]?.genreId;
      if (!genreId) return false;
      return (
        ['electronic', 'funk_soul'].indexOf(
          state.entities.genres.entities[genreId]?.handler || ''
        ) >= 0
      );
    });

    const registeredAt = useSelector((state: ReduxState) => {
      return state.entities.releases.entities[releaseId]?.registeredAt;
    });

    const originalReleaseDate = useSelector((state: ReduxState) =>
      selector(state, 'originalReleaseDate')
    );

    const releaseAt = useSelector((state: ReduxState) =>
      selector(state, 'releaseAt')
    );

    const shouldOpenOptional = useSelector(
      (state: ReduxState) =>
        (
          selector(state, 'coverCopyrightYear') ||
          selector(state, 'customBarcode') ||
          selector(state, 'promotionalText') ||
          selector(state, 'version') ||
          selector(state, 'originalReleaseDate') ||
          selector(state, 'catalogNumber')
        )?.length > 0
    );
    const [opened, setOpened] = useState(() => shouldOpenOptional);

    const displayArtist = useFormDisplayArtist(form);
    const { handleField, handleNameField, handleBarcode } =
      useReleaseFieldsHandlers({ form, releaseId });

    const revenueSplits = useSelector((state: ReduxState) =>
      selector(state, 'revenueSplits')
    );

    const revenueSplitsRef = useRef(revenueSplits);

    useEffect(() => {
      if (
        revenueSplits !== null &&
        revenueSplitsRef.current !== revenueSplits
      ) {
        revenueSplitsRef.current = revenueSplits;
        handleField('revenueSplits')(null, revenueSplits);
      }
    }, [revenueSplits]);

    const showHelpWindow = useContext(HelpWindowContext);
    const { t } = useTranslation();

    const releaseNameValue = useMemo<
      React.ContextType<typeof EntityFormContext>
    >(
      () => ({ entity: 'releaseNames', id: releaseNameId || -1 }),
      [releaseNameId]
    );

    const makeHelpClick = useCallback(
      memoize((title, desc) => () => showHelpWindow(title, desc)),
      []
    );
    const onClickOptional = useCallback(() => {
      setOpened(!opened);
    }, [opened]);

    const [splitsOpened, setSplitsOpened] = useState(
      () => revenueSplits?.length > 0
    );
    const onClickSplits = useCallback(() => {
      setSplitsOpened(!splitsOpened);
    }, [splitsOpened]);

    const premiumLabelFeature = useCustomerFeatureRollout({
      feature: 'custom-labels',
      rolloutKey: 'tiered-subs',
      fallback: true,
    });

    const nameEditingEnabled = useCustomerFeatureRollout({
      feature: 'post-registration-names-editing',
      rolloutKey: 'tiered-subs',
      fallback: true,
    });

    const revenueSplitsAllowed = useCustomerFeatureRollout({
      fallback: false,
      feature: 'revenue-splits', // TODO: use better,
      rolloutKey: 'revenue-splits',
    });
    const [revenueSplitsEnabled] = useFeature({ featureKey: 'revenue-splits' });
    const { open: openUpsell } = useSubscriptionUpsell();

    // TODO: move name editing to imddata once rollout is complete
    const disabledTitle =
      (editableFields && !editableFields.title) ||
      (!nameEditingEnabled && registeredAt);

    const disabledVersion =
      (editableFields && !editableFields.version) ||
      (!nameEditingEnabled && registeredAt);

    const availableCoverCopyrightRange =
      originalReleaseDate !== releaseAt
        ? [
            ...new Array(
              CURRENT_YEAR - (new Date(originalReleaseDate).getFullYear() - 1)
            )
              .fill(0)
              .map((v, idx) => ({
                label: CURRENT_YEAR - idx,
                value: CURRENT_YEAR - idx,
              })),
          ]
        : [];

    return (
      <>
        <section css={sectionStyle}>
          <EntityFormContext.Provider value={releaseNameValue}>
            <FieldUpdatable
              name="title"
              testId="title"
              disabled={!!disabledTitle}
              // @ts-ignore
              onChange={handleNameField('title')}
              label={t('release-name')}
              helperText={t('release-helptext-short-release-name')}
              css={fieldStyle}
              onClickHelp={makeHelpClick(
                t('release-name'),
                t('release-helptext-release-name')
              )}
              component={NewInputField}
            />
            {app !== 'admin' && disabledTitle && (
              <SubscriptionFeatureGate
                style={{ marginBottom: '16px' }}
                title={t('name-editing-upsell')}
                action={t('upgrade')}
                onActionClick={() => {
                  openUpsell({
                    analytics: { detail: 'name' },
                    section: 'music-distribution',
                    feature: 'post-registration-names-editing',
                  });
                }}
              />
            )}
            <FieldUpdatable
              name="copyrightText"
              // @ts-ignore
              onChange={handleField('copyrightText')}
              disabled={editableFields ? !editableFields.copyrightText : false}
              testId="copyrightText"
              label={t('copyright-recording')}
              onClickHelp={makeHelpClick(
                t('copyright-recording'),
                t('release-helptext-copyright-recording')
              )}
              // @ts-ignore
              css={fieldStyle}
              component={NewInputField}
            />
          </EntityFormContext.Provider>

          {displayGenre && (
            <FieldUpdatable
              name="genreId"
              onChange={handleField('genreId')}
              disabled={editableFields ? !editableFields.genreId : false}
              testId="genreId"
              style={{ marginBottom: '16px' }}
              component={GenreSelectField}
            />
          )}
        </section>

        <section css={sectionStyle}>
          <FieldUpdatable
            name="artists"
            size="large"
            label={t('artists-details')}
            onClickHelp={makeHelpClick(
              t('artists'),
              t('track-helptext-artists')
            )}
            component={LabelTextField}
          />

          {displayArtist && (
            <Content key="displayArtist" css={displayArtistStyle}>
              {displayArtist}
            </Content>
          )}
          <FieldUpdatable
            name="artists"
            enableArtistLimit={enableArtistLimit}
            testId="artists"
            disabled={!editableFields?.artists}
            onChange={handleField('artists')}
            label={t('artists')}
            component={ArtistJoinField}
          />
        </section>

        <LabelSection>
          {/* TODO: fix when LabelSelectorField has types
 // @ts-ignore */}
          <FieldUpdatable
            name="labelId"
            premiumLabelLimit={!premiumLabelFeature ? 0 : 'none'}
            isElectronic={isElectronic}
            onChange={handleField('labelId')}
            disabled={editableFields ? !editableFields.labelId : false}
            component={LabelSelectorField}
          />
        </LabelSection>

        <section css={sectionStyle}>
          <OverlineText label={t('optional-fields')} size="large" />
          <Subheader>
            <Body>{t('optional-release-fields-description')}</Body>
            <Button
              style={{ margin: '0 16px' }}
              size="small"
              icon={
                !opened ? ComponentIcons.ChevronDown : ComponentIcons.ChevronUp
              }
              text={opened ? t('close') : t('edit')}
              onClick={onClickOptional}
            />
          </Subheader>
          {opened && (
            <div>
              <FieldUpdatable
                name="version"
                testId="version"
                disabled={!!disabledVersion}
                // @ts-ignore
                onChange={handleNameField('version')}
                label={t('version')}
                helperText={t('release-helptext-short-version')}
                css={fieldStyle}
                onClickHelp={makeHelpClick(
                  t('version'),
                  t('release-helptext-version')
                )}
                component={NewInputField}
              />
              <FieldUpdatable
                name="customBarcode"
                disabled={
                  editableFields ? !editableFields.customBarcode : false
                }
                renderInput={renderBarcodeInput}
                onChange={handleBarcode}
                label={t('barcode-leave-blank-to-get-for-free')}
                helperText={t('release-helptext-short-barcode')}
                onClickHelp={makeHelpClick(
                  t('barcode-leave-blank-to-get-for-free'),
                  t('release-helptext-barcode')
                )}
                component={NewInputField}
              />

              <FieldUpdatable
                name="catalogNumber"
                disabled={
                  editableFields ? !editableFields.catalogNumber : false
                }
                // @ts-ignore
                onChange={handleField('catalogNumber')}
                label={t('catalogue-no')}
                helperText={t('release-helptext-short-catalogue-no')}
                onClickHelp={makeHelpClick(
                  t('catalogue-no'),
                  t('release-helptext-catalogue-no')
                )}
                component={NewInputField}
                css={fieldStyle}
              />

              {displayPromotionalText && (
                <FieldUpdatable
                  name="promotionalText"
                  multiline={true}
                  component={NewInputField}
                  label={t('promotional-text')}
                  helperText={t('release-helptext-short-promotional-text')}
                  disabled={
                    editableFields ? !editableFields.promotionalText : false
                  }
                  onClickHelp={makeHelpClick(
                    t('promotional-text'),
                    t('release-helptext-promotional-text')
                  )}
                  css={fieldStyle}
                  // @ts-ignore
                  onChange={handleNameField('promotionalText')}
                />
              )}

              <OriginalReleaseDateSelectorField
                onChange={handleField('originalReleaseDate')}
                disabled={
                  editableFields ? !editableFields.originalReleaseDate : false
                }
              />
              {originalReleaseDate && (
                <FieldUpdatable
                  key="year"
                  testId="coverCopyrightYear"
                  name="coverCopyrightYear"
                  // @ts-ignore
                  onChange={handleField('coverCopyrightYear')}
                  placeholder={t('cover-copyright-year-placeholder')}
                  disabled={
                    editableFields
                      ? !editableFields.coverCopyrightYear ||
                        availableCoverCopyrightRange.length === 0
                      : false
                  }
                  label={t('cover-copyright-year')}
                  onClickHelp={makeHelpClick(
                    t('cover-copyright-year'),
                    t('release-helptext-cover-copyright-year')
                  )}
                  css={fieldStyle}
                  // @ts-ignore
                  data={availableCoverCopyrightRange}
                  component={SelectField}
                />
              )}
            </div>
          )}
        </section>

        {revenueSplitsEnabled && (
          <section css={sectionStyle}>
            <OverlineText
              label={t('revenue-splits')}
              size="large"
              onClickHelp={() => {
                showHelpWindow(
                  t('revenue-splits'),
                  t('revenue-splits-helptext-description')
                );
              }}
            />

            {!revenueSplitsAllowed && (
              <BuyOrSubOffer
                subscribeContext={{ analytics: { detail: 'rev-splits' } }}
                title={t('unlock-revenue-splits')}
                onDecideSubscribe={() => {
                  if (window.analytics) {
                    window.analytics.track(
                      'FT open upsell on release revenue splits'
                    );
                  }
                }}
                description={t('unlock-revenue-splits-description')}
                style={{ marginBottom: '16px' }}
              />
            )}
            <Subheader>
              {editableFields.genreId && (
                <Body>
                  {t('release-revenue-splits-will-apply-to-all-new-tracks')}
                </Body>
              )}
              {revenueSplitsAllowed && (
                <Button
                  style={{ margin: '0 16px', flexShrink: 0 }}
                  size="small"
                  icon={
                    !splitsOpened
                      ? ComponentIcons.ChevronDown
                      : ComponentIcons.ChevronUp
                  }
                  text={splitsOpened ? t('close') : t('edit')}
                  onClick={onClickSplits}
                />
              )}
            </Subheader>
            {(splitsOpened || !revenueSplitsAllowed) && (
              <div>
                <FieldArray
                  name="revenueSplits"
                  disabled={!revenueSplitsAllowed}
                  // @ts-ignore
                  component={RevenueSplitsField}
                />
              </div>
            )}
          </section>
        )}
      </>
    );
  }
);

ExtendedFieldsColumn.displayName = 'ExtendedFieldsColumn';
