import styled from '@emotion/styled';
import { getSubscriptionCadence } from 'components';
import { useCurrentCustomer, useCurrentCustomerSubscription } from 'imddata';
import { useFeature } from 'imdfeature';
import { useSubscriptionUpsell } from 'imdshared';
import { H4 } from 'imdui';
import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  gap: 32px;
  background: var(--surface-bg-inverted, #1a1a1a);
  ${H4} {
    color: white;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
`;
const Button = styled.button`
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 2px solid var(--foreground-fg-inverted, rgba(255, 255, 255, 0.95));
  background: var(--surface-bg-inverted, #1a1a1a);
  /* Shadow/Tab Item */
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.1);

  color: var(--foreground-fg-inverted, rgba(255, 255, 255, 0.95));
  font-feature-settings: 'cpsp' on;
  /* Component/Button Text */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  text-transform: uppercase;
`;

export const useSubsPromo = (): [boolean, MouseEventHandler] => {
  const { entry: customer } = useCurrentCustomer();
  const subscription = useCurrentCustomerSubscription();
  const { open } = useSubscriptionUpsell();
  const [enabledPromo] = useFeature({ featureKey: 'promo-subs' });

  const cadence = subscription
    ? getSubscriptionCadence(subscription.subscriptionId)
    : null;

  const futureCadence = subscription?.renewToId
    ? getSubscriptionCadence(subscription.renewToId)
    : null;

  const shouldHide =
    !customer ||
    !enabledPromo ||
    cadence === 'yearly' ||
    futureCadence === 'yearly';

  const handleClick = useCallback<MouseEventHandler>(() => {
    open({});
  }, []);

  return [!shouldHide, handleClick];
};

export const BlackBanner = ({
  onClick,
  text,
  buttonText,
}: {
  onClick: MouseEventHandler;
  text: string;
  buttonText: string;
}) => {
  return (
    <Wrapper>
      <H4>{text}</H4>
      <Button onClick={onClick}>{buttonText}</Button>
    </Wrapper>
  );
};

export const PromoBanner = () => {
  const [showPromo, onClick] = useSubsPromo();
  const { t } = useTranslation();
  if (!showPromo) return null;

  return (
    <BlackBanner
      text={t('promo-banner-text')}
      onClick={onClick}
      buttonText={t('promo-banner-button-text')}
    />
  );
};
