import styled from '@emotion/styled';
import { Action } from '../../text';

export const ButtonText = styled(Action)<{
  size: 'large' | 'medium' | 'small';
  leftIcon?: boolean;
  showLoading?: boolean;
}>`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  text-align: left;
  transition: all 0.1s;
  font-size: ${({ size }) =>
    size === 'large' ? '17px' : size === 'medium' ? '15px' : '13px'};

  opacity: ${({ showLoading }) => (showLoading ? '0 !important' : 1)};
`;
