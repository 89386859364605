import { useSubscriptionUpsell } from 'imdshared';
import { BodyS, H4 } from '@imus/services-ui/src/Text';

import { Button, ToggleSwitch, ButtonText } from 'imdui';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useFeature } from 'imdfeature';
import { accentThemes } from '@imus/base-ui';
const BuyOrSubRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  ${ButtonText} {
    line-height: 20px;
    font-size: 14px;
    text-transform: uppercase;
  }
  & > ${BodyS} {
    color: var(--fg-2);
    text-transform: uppercase;
  }

  & > button {
    flex: 1;
  }
`;

const ToggleText = styled.div`
  display: flex;
  align-self: center;
  gap: 16px;
`;

const ButtonToggleBase = ({
  className,
  onCheck,
  text,
  checked,
}: {
  className?: string;
  onCheck: (e: any, v: boolean) => void;
  text: string;
  checked: boolean;
}) => {
  return (
    <Button
      className={className}
      onClick={(e) => {
        onCheck(e, !checked);
      }}
    >
      <ToggleText>
        <ToggleSwitch checked={checked} />
        <ButtonText size="small">{text}</ButtonText>
      </ToggleText>
    </Button>
  );
};
const ButtonToggle = styled(ButtonToggleBase)`
  background: var(--bg-3);
`;

const BuyOrSubWrapper = styled.div<{ hasOnDecideBuy: boolean }>`
  ${accentThemes.tertiary}
  display: ${({ hasOnDecideBuy }) => (hasOnDecideBuy ? 'grid' : 'flex')};
  gap: 24px;

  padding: 24px;
  margin-bottom: 32px;

  border-radius: 20px;
  border: 1px solid var(--accent, #b48b00);
  background: var(--accent-surface-container, #fffbff);

  /* Shadow/2 */
  box-shadow:
    0px 0px 8px 0px rgba(0, 0, 0, 0.05),
    0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  ${({ hasOnDecideBuy }) =>
    hasOnDecideBuy
      ? ''
      : `
    ${BuyOrSubRow} {
    margin-left: auto;
    width: auto;
    }
   `};
`;

export const BuyOrSubOffer = ({
  decideBuy,
  title,
  style,
  price,
  description,
  onDecideSubscribe,
  onDecideBuy,
  subscribeContext,
}:
  | {
      style?: React.CSSProperties;
      price: string;
      decideBuy: boolean;
      onDecideSubscribe?: () => void;
      onDecideBuy: (v: boolean) => void;
      description: React.ReactNode;
      subscribeContext?: Parameters<
        ReturnType<typeof useSubscriptionUpsell>['open']
      >[0];
      title: string;
    }
  | {
      style?: React.CSSProperties;
      onDecideSubscribe?: () => void;
      subscribeContext?: Parameters<
        ReturnType<typeof useSubscriptionUpsell>['open']
      >[0];
      description: React.ReactNode;
      title: string;
      price?: undefined;
      decideBuy?: undefined;
      onDecideBuy?: undefined;
    }) => {
  const { t } = useTranslation();
  const [paygFeature] = useFeature({ featureKey: 'payg' });
  const { open: openUpsell } = useSubscriptionUpsell();

  return (
    <BuyOrSubWrapper style={style} hasOnDecideBuy={!!onDecideBuy}>
      <div>
        <H4 style={{ marginBottom: '4px' }}>{title}</H4>
        <BodyS>{description}</BodyS>
      </div>

      <BuyOrSubRow>
        <Button
          primary={true}
          appearance={'fill'}
          text={t('subscribe')}
          onClick={() => {
            if (onDecideSubscribe) {
              onDecideSubscribe();
            }
            openUpsell(subscribeContext);
          }}
        />
        {paygFeature && onDecideBuy && (
          <>
            <BodyS>{t('or')}</BodyS>
            <ButtonToggle
              text={`${t('pay')} ${price}`}
              checked={decideBuy}
              onCheck={(_e, v) => onDecideBuy(v)}
            />
          </>
        )}
      </BuyOrSubRow>
    </BuyOrSubWrapper>
  );
};
