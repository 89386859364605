import styled from '@emotion/styled';
import { appear, pulse } from '../../shared/keyframes';

export const StyledButton = styled.button<{ inline?: boolean }>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${({ inline }) => (inline ? '24px' : '40px')};
  height: ${({ inline }) => (inline ? '24px' : '40px')};
  border-radius: 50%;

  ::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    border-radius: inherit;
    transition: background-color 0.1s ease-in-out;
  }

  :hover::before {
    background: var(--state-hover-on-surface);
  }

  :active::before {
    background: var(--state-active-on-surface);
  }

  :focus::before {
    animation:
      ${appear} ease-in-out 0.2s forwards,
      ${pulse} 1.5s 0.2s ease-in-out infinite;
  }
`;
