import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import { oldBaseTheme } from '@imus/base-ui';
import type { Transition } from 'framer-motion';
import { motion } from 'framer-motion';
import { ErrorBoundary } from 'imdshared';
import { BodySmall, Centered, DOMNodeContext, LoadingIndicator } from 'imdui';
import { Suspense, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';

const TemplateContainer = styled.div`
  height: 100vh;
  padding-top: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: margin 0.2s ease;
  will-change: margin;
  position: relative;
  // Editor padding
  margin-right: 497px;

  @media only screen and (min-width: 400px) and (max-width: 767px) {
    height: auto;
    margin: 16px 32px;
    padding-top: 0;
  }
  @media only screen and (max-width: 399px) {
    height: auto;
    margin: 16px 0px;
    padding-top: 0;
  }
`;

const TemplateWidthContainer = styled.div<{
  height?: string;
  width?: string;
  containerName?: string;
}>`
  display: flex;
  height: 100%;
  width: 100%;
  margin: 32px;
  overflow: hidden;
  box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background: #fafafa;
  & > * {
    height: 100% !important;
    width: 100% !important;
    position: relative !important;
  }
  ${({ containerName }) =>
    containerName &&
    `container-name: ${containerName}; container-type: inline-size;`}

  max-height: ${({ height }) => height};
  max-width: ${({ width }) => width};
`;

const OverlayContainer = styled(motion.div)`
  ${oldBaseTheme}
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;
const dimBefore = { opacity: 0 };
const dimAfter = { opacity: 1 };

const animConfig: Transition = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
};

const Status = styled.div`
  position: absolute;
  right: 48px;
  bottom: 48px;
  display: flex;
  align-items: center;
  gap: 16px;

  max-width: 1280;
`;

const disableScroll = css`
  body {
    overflow: hidden;
  }
`;

export const WysiwygTemplateContainer = ({
  width = '1280px',
  height = '720px',
  style,
  status,
  containerName,
  children,
}: {
  width?: string;
  status?: 'generating' | 'generated' | 'not-generated' | null;
  height?: string;
  style?: React.CSSProperties;
  containerName: string;
  children: React.ReactNode;
}) => {
  const { dropdownNode: contentNode } = useContext(DOMNodeContext);
  const { t } = useTranslation();
  return (
    <Portal node={contentNode?.current}>
      <OverlayContainer
        style={style}
        initial={dimBefore}
        animate={dimAfter}
        exit={dimBefore}
        transition={animConfig}
      >
        <Global styles={disableScroll} />
        <TemplateContainer>
          <TemplateWidthContainer
            containerName={containerName}
            width={width}
            height={height}
          >
            <ErrorBoundary>
              <Suspense
                fallback={
                  <Centered>
                    <LoadingIndicator size="large" />
                  </Centered>
                }
              >
                {children}
              </Suspense>
            </ErrorBoundary>
          </TemplateWidthContainer>
          <Status>
            {status === 'generating' ? (
              <>
                <LoadingIndicator></LoadingIndicator>{' '}
                <BodySmall>{t('generating')}</BodySmall>
              </>
            ) : (
              ''
            )}
          </Status>
        </TemplateContainer>
      </OverlayContainer>
    </Portal>
  );
};
