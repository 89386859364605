// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Grid, useSubscriptionUpsell } from 'imdshared';
import { PageTemplate, SubscriptionHeader } from 'components';
import { Card, Button, OverlineText } from 'imdui';
import { useCurrentCustomerSubscription } from 'imddata';
import {
  ReferralCard,
  DistributionInProgressCard,
  BalanceCard,
  TrendCard,
  PromoCard,
} from './components';
import { useHistory } from 'react-router';
import { DashboardProductRow } from './components/ProductRow/ProductRow';
import { NewsRow, TipsRow } from './components/NewsTipsRows';
import type { SubscriptionId } from 'imddata/types/entities';
import { mapSubscriptionIdToTheme } from 'components/molecules/SubscriptionCard';

const Spacer = styled.div`
  grid-column: 1 / -1;
  margin-bottom: 8px;

  span {
    color: var(--on-surface, #1c1c1b);
    font-feature-settings:
      'ss01' on,
      'cv11' on;
    font-family: Barlow;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.225px;
    text-transform: uppercase;
  }
`;

const SubsGridRow = styled(Grid)`
  display: grid;

  gap: 16px;
`;

const GridRow = styled(Grid)``;

const DashboardWrapper = styled.div`
  display: grid;
  row-gap: 40px;
`;

const SubscriptionCard = styled(Card)<{
  subscriptionId: SubscriptionId | 'free';
}>`
  ${({ subscriptionId }) => mapSubscriptionIdToTheme(subscriptionId)}

  height: 100%;
  width: 100%;
  min-height: initial;
  text-align: left;
  padding: 24px;
  gap: 4px;
  & > div {
    align-items: center;
    flex: 1;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
  }
`;

const ActiveDashboard: React.FC = () => {
  const { t } = useTranslation();

  const subscription = useCurrentCustomerSubscription();

  const { open: openUpsell } = useSubscriptionUpsell();

  const history = useHistory();
  return (
    <PageTemplate>
      <Helmet>
        <title>{t('page-title-dashboard')}</title>
      </Helmet>

      <DashboardWrapper>
        <PromoCard />

        <GridRow columns={1}>
          <Spacer>
            <span>{t('start-off')}</span>
          </Spacer>
          <SubsGridRow columns={3}>
            <DashboardProductRow />
          </SubsGridRow>
        </GridRow>

        <Grid columns={1}>
          <GridRow columns={3}>
            <Spacer>
              <span>{t('activity')}</span>
            </Spacer>
            <SubscriptionCard
              subscriptionId={subscription?.subscriptionId || 'free'}
            >
              <OverlineText size="large" label={t('current-subscription')} />
              <div>
                <div>
                  <SubscriptionHeader
                    subscriptionId={subscription?.subscriptionId || 'free'}
                  />
                </div>
                <Button
                  size="small"
                  primary={!!subscription}
                  text={t('manage')}
                  onClick={() => {
                    if (subscription) {
                      history.push('/account');
                      return;
                    }
                    openUpsell();
                  }}
                />
              </div>
            </SubscriptionCard>

            <ReferralCard />
            <BalanceCard />
          </GridRow>

          <GridRow columns={3}>
            <DistributionInProgressCard />
            <TrendCard />
          </GridRow>
        </Grid>
        <GridRow columns={3} id="news">
          <Spacer>
            <span>{t('news')}</span>
          </Spacer>
          <NewsRow />
        </GridRow>
        <GridRow columns={3}>
          <Spacer>
            <span>{t('tips')}</span>
          </Spacer>
          <TipsRow />
        </GridRow>
      </DashboardWrapper>
    </PageTemplate>
  );
};

export default ActiveDashboard;
