import React, { useState } from 'react';
import { Text, Branding } from '@imus/services-ui';
import type { TemplateData } from '../types/TemplateData';
import Image from 'next/image';
import {
  brandingOverlayStyle,
  scrollWrapperStyle,
  contentSectionStyle,
  coverSectionStyle,
  coverArtworkStyle,
  coverContentStyle,
  coverTextStyle,
  childrenWrapperStyle,
} from './styles';
import { useTranslation } from 'next-i18next';

type Props = {
  data: TemplateData;
  children: React.ReactNode;
  baseUrl?: string;
  style?: React.CSSProperties;
};

export const Layout: React.FC<Props> = ({ data, style, children }) => {
  const { t } = useTranslation();
  const [coverUrlOptimized, setCoverUrlOptimized] = useState<string>();
  const coverUrl = data.release.cover?.url || '';

  return (
    <div
      style={style}
      css={scrollWrapperStyle}
      data-page-template={`release-page-template-${
        data.free ? 'non-premium' : 'premium'
      }`}
    >
      <div
        css={coverSectionStyle}
        style={{
          backgroundImage: `url(${coverUrlOptimized})`,
        }}
      >
        <Image
          alt="cover"
          width={800}
          priority={true}
          height={800}
          onLoadingComplete={(img) => {
            setCoverUrlOptimized(img.src);
          }}
          css={coverArtworkStyle}
          src={coverUrl}
        />

        <div css={coverContentStyle}>
          <div css={coverTextStyle}>
            <Text.CoverTitle data-test-id="ReleasePageTemplate-Title">
              {data.release.title}
            </Text.CoverTitle>

            <Text.CoverSubtitle>
              {data.release.displayArtist}
            </Text.CoverSubtitle>
          </div>
        </div>
      </div>

      <div css={contentSectionStyle} data-test-id="ContentSection">
        <div css={childrenWrapperStyle}>{children}</div>
        {(data.free || data.branded) && (
          <div css={brandingOverlayStyle}>
            <Branding
              callToActionUrl={t('signup-now-link')}
              callToAction={t('signup-now')}
              description={t('branding-description')}
              imusicianUrl={t('imusician-link')}
              title={t('powered-by')}
            />
          </div>
        )}
      </div>
    </div>
  );
};
